import React, {Component} from 'react'

import MultiGridHeader from './MultiGridHeader'
import MultiGridRow from './MultiGridRow'
import MultiGridControls from './MultiGridControls'
import MultiGridTotals from './MultiGridTotals'
import TabletControls from "../Quickview/TabletControls";
import Button from "../Button";
import { ReactComponent as BinSvg } from '../assets/bin.svg';
import {isTablet} from "../../utils/utils";

import {addLivePricesToProduct} from '../../utils/priceUtils'

import './MultiGrid.scss'

class MultiGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productRows: [],
            focusedInputBarcode: null,
            valuesSet: false,
            fetchedLivePrices: false,
            selectedColorSimone: null
        }

        this.alterRow = this.alterRow.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.resetGridAction = this.resetGridAction.bind(this);
    }

    componentDidMount() {
        this.calculateGridRows()
    }

    findProductSizes() {
        const {product} = this.props

        let sizes = [];

        if (product.variations) {
            for (let i = 0; i < product.variations.length; i++) {
                if (sizes.includes(product.variations[i].sizeName)) {
                    continue
                }

                sizes.push(product.variations[i].sizeName)
            }
        }

        return sizes
    }

    findProductSizesSimone() {
        const {product} = this.props
        let sizes = new Set()
        let cups = new Set()
        let colors = {}

        for (let i = 0; i < product.variations.length; i++) {
            //if( product.variations[i].SeasonType !== 'PREORDER' ){
                sizes.add(product.variations[i].sizeName)
                cups.add(product.variations[i].cupName)

                if (colors[product.variations[i].colorId]) {
                    continue;
                }

            if( product.factory === 'Simone' && product.variations[i].stock === 999 ){
                const selectedSeason = localStorage.getItem("selectedSeason");
                if( selectedSeason !== product.variations[i].season && product.variations[i].season !== "0000" ){
                    continue;
                }
            }
            else if( product.factory === 'Simone' &&  product.variations[i].b2bcustomers !== "1" ){
                continue;
            }

                colors[product.variations[i].colorId] = {
                    name: product.variations[i].colorName,
                    hex: product.variations[i].colorHex,
                    code: product.variations[i].colorCode,
                    colorId: product.variations[i].colorId
                }
            //}
        }

        return {
            sizesSimone: Array.from(sizes).sort(function(a, b){return a-b}),
            cups: Array.from(cups),
            colors: Object.values(colors)
        }
    }

    getValuesFromCartItems(cartItems, barcode) {
        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].barcode === barcode) {
                return parseInt(cartItems[i].qty)
            }
        }

        return 0
    }

    calculateGridRows(livePrices = []) {
        let {product, cartItems} = this.props
        let rows = [];


        if (product.type === 'variable') {
            //Check for live prices
            if (livePrices && livePrices.length) {
                product = addLivePricesToProduct(product, livePrices)
            }

            for (let i = 0; i < product.variations.length; i++) {
                //Copy object
                let variationItem = JSON.parse(JSON.stringify(product.variations[i]));

                let inputValue = 0;

                if (cartItems && cartItems.length) {
                    //Try to find in inputValues
                    inputValue = this.getValuesFromCartItems(cartItems, variationItem.barcode)
                }

                let tempProductVar = this.state.productRows.filter( rowTemp => rowTemp.colorCode === variationItem.colorCode )

                //Empty Array
                if (!rows.length) {
                    rows.push({
                        _id: product._id,
                        code: product.code,
                        colorName: variationItem.colorName,
                        colorCode: variationItem.colorCode,
                        colorHex: (variationItem.colorHex) ? variationItem.colorHex : null,
                        colorId: variationItem.colorId,
                        sizes: [
                            {
                                barcode: variationItem.barcode,
                                sizeName: variationItem.sizeName,
                                sizeCode: variationItem.sizeCode,
                                sizeId: variationItem.sizeId,
                                inputValue: ( tempProductVar.length && tempProductVar[0].sizes.filter(tempSize => tempSize.barcode === variationItem.barcode )[0].inputValue ) || inputValue,
                                price: variationItem.price,
                                stock: variationItem.stock,
                                stocks: variationItem.stocks,
                                storeView: variationItem.storeView,
                                cupCode: variationItem.cupCode,
                                cupId: variationItem.cupId,
                                cupName: variationItem.cupName,
                            }
                        ]
                    })
                    continue;
                }

                let colorExists = false

                for (let j = 0; j < rows.length; j++) {
                    if (
                        rows[j].colorId === variationItem.colorId &&
                        rows[j].colorCode === variationItem.colorCode
                    ) {
                        rows[j].sizes.push({
                            barcode: variationItem.barcode,
                            sizeName: variationItem.sizeName,
                            sizeCode: variationItem.sizeCode,
                            sizeId: variationItem.sizeId,
                            price: variationItem.price,
                            stock: variationItem.stock,
                            stocks: variationItem.stocks,
                            inputValue: ( tempProductVar.length && tempProductVar[0].sizes.filter(tempSize => tempSize.barcode === variationItem.barcode )[0].inputValue ) || inputValue,
                            storeView: variationItem.storeView,
                            cupCode: variationItem.cupCode,
                            cupId: variationItem.cupId,
                            cupName: variationItem.cupName,
                        })
                        colorExists = true;
                        break;
                    }
                }

                if (!colorExists) {
                    rows.push({
                        _id: product._id,
                        code: product.code,
                        colorName: variationItem.colorName,
                        colorCode: variationItem.colorCode,
                        colorHex: (variationItem.colorHex) ? variationItem.colorHex : null,
                        colorId: variationItem.colorId,
                        sizes: [
                            {
                                barcode: variationItem.barcode,
                                sizeName: variationItem.sizeName,
                                sizeCode: variationItem.sizeCode,
                                sizeId: variationItem.sizeId,
                                price: variationItem.price,
                                stock: variationItem.stock,
                                stocks: variationItem.stocks,
                                inputValue: ( tempProductVar.length && tempProductVar[0].sizes.filter(tempSize => tempSize.barcode === variationItem.barcode )[0].inputValue ) || inputValue,
                                storeView: variationItem.storeView,
                                cupCode: variationItem.cupCode,
                                cupId: variationItem.cupId,
                                cupName: variationItem.cupName,
                            }
                        ]
                    })
                }
            }
        } else {
            //Simple Product Case
            let inputValue = 0;

            if (cartItems && cartItems.length) {
                //Try to find in inputValues
                inputValue = this.getValuesFromCartItems(cartItems, product.code)
            }

            rows.push({
                ...product,
                inputValue
            })
        }

        let responsePayload = {
            productRows: rows
        }

        if (livePrices && Object.keys(livePrices).length) {
            responsePayload.fetchedLivePrices = true
        }

        this.setState(responsePayload)
    }

    alterInput(focusedBarcode, type) {
        if (!focusedBarcode || !type) {
            return null
        }

        const {productRows} = this.state

        let alteredProductRows = [];

        for (let i = 0; i < productRows.length; i++) {
            let row = Object.assign({}, productRows[i])

            //Variable
            let sizes = [];

            for (let j = 0; j < row.sizes.length; j++) {
                let size = row.sizes[j];

                let sizeInputValue = parseInt(size.inputValue);
                let sizeStockValue = parseInt(size.stock);

                if (size.barcode === focusedBarcode) {
                    if (type === 'plus') {
                        if (sizeInputValue < sizeStockValue) {
                            sizeInputValue++;
                        }
                    } else {
                        if (sizeInputValue > 0) {
                            sizeInputValue--;
                        }
                    }
                }

                size.inputValue = sizeInputValue

                sizes.push(size)
            }

            row.sizes = sizes;
            alteredProductRows.push(row)
        }

        this.setState({
            productRows: alteredProductRows
        })
    }

    alterRow(colorId, type, cup = null) {
        const {productRows} = this.state

        let alteredProductRows = [];

        for (let i = 0; i < productRows.length; i++) {
            let row = Object.assign({}, productRows[i])

            if ( row.colorId === colorId && colorId ) {
                //Variable
                let sizes = [];

                for (let j = 0; j < row.sizes.length; j++) {
                    let size = row.sizes[j];
                    if (size.stock && ( size.cupCode === cup || cup === null )) {
                        if (type === 'plus') {
                            if (parseInt(size.stock) >= (parseInt(size.inputValue) + 1)) {
                                size.inputValue++;
                            }
                        } else {
                            if (size.inputValue > 0) {
                                size.inputValue--;
                            }
                        }
                    }
                    sizes.push(size)
                }

                row.sizes = sizes;
            } else {
                //Simple
                if (type === 'plus') {
                    row.inputValue++;
                } else {
                    if (row.inputValue > 0) {
                        row.inputValue--;
                    }
                }
            }

            alteredProductRows.push(row)
        }

        this.setState({
            productRows: alteredProductRows
        })
    }

    handleInputChange(e, barcode, colorId) {
        const {productRows} = this.state

        let alteredProductRows = [];

        for (let i = 0; i < productRows.length; i++) {

            let row = Object.assign({}, productRows[i])

            if (colorId) {
                //Variable
                if (productRows[i].colorId !== colorId) {
                    alteredProductRows.push(row)
                    continue;
                }

                let sizes = [];

                for (let j = 0; j < row.sizes.length; j++) {
                    let size = row.sizes[j];

                    if (size.barcode === barcode) {
                        let qty = parseInt(e.target.value)
                        const itemStock = parseInt(size.stock)

                        if (itemStock && qty > itemStock) {
                            qty = itemStock
                        }

                        size.inputValue = qty;
                    }

                    sizes.push(size)
                }
            }
            {
                //Simple
                row.inputValue = e.target.value;
            }

            alteredProductRows.push(row)
        }

        this.setState({
            productRows: alteredProductRows
        })
    }

    resetGridAction() {
        const {productRows} = this.state

        this.setState({
            productRows: productRows.map((productRow) => {
                if (productRow.sizes && productRow.sizes.length) {
                    productRow.sizes.map((size) => {
                        size.inputValue = 0;
                        return size
                    })
                } else {
                    productRow.inputValue = 0
                }

                return productRow
            })
        })
    }

    addToCart() {
        const {addToCartAction} = this.props;
        const {productRows} = this.state;

        //Get product barcodes with inputValues
        let productsPayload = []

        for (let i = 0; i < productRows.length; i++) {
            let productRow = Object.assign({}, productRows[i])
            let sizes = productRow.sizes

            if (sizes && sizes.length) {
                //Variable
                for (let j = 0; j < sizes.length; j++) {
                    if (!sizes[j]) {
                        continue;
                    }

                    let inputQty = 0

                    if (sizes[j].inputValue) {
                        inputQty = parseInt(sizes[j].inputValue)
                    }

                    productsPayload.push({
                        productId: productRow._id,
                        barcode: sizes[j].barcode,
                        qty: sizes[j].inputValue
                    })
                }
            } else {
                //Simple
                productsPayload.push({
                    productId: productRow._id,
                    barcode: productRow.code,
                    qty: productRow.inputValue
                })
            }
        }

        //Check if everything is null and prompt
        let everythingIsNull = true
        productsPayload.filter((item) => {
            if (parseInt(item.qty) > 0) {
                everythingIsNull = false
            }
        })

        if (!everythingIsNull) {
            addToCartAction(productsPayload)
        } else {
            alert('Please fill at least one input')
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Set values from cart
        if (this.props.cartItems) {
            if (
                !this.state.valuesSet &&
                !prevProps.cartItems.length &&
                this.props.cartItems.length
            ) {
                this.calculateGridRows()
            }

            //Check for live prices
            if (!this.state.fetchedLivePrices && this.props.livePrices && this.props.livePrices.length) {
                this.calculateGridRows(this.props.livePrices)
            }
        }
    }

    printMultiGridRows(hidePrices) {
        const {productRows, focusedInputBarcode} = this.state
        const {currency, variationPrices} = this.props

        if (!productRows.length) {
            return null;
        }

        return productRows.map((item, key) => {
            return <MultiGridRow
                selectedColor={this.props.selectedColor}
                setSelectedColor={this.props.setSelectedColor}
                hidePrices={hidePrices}
                focusedInputBarcode={focusedInputBarcode}
                currency={currency}
                key={key}
                code={item.code}
                item={item}
                variationPrices={variationPrices}
                alterRow={this.alterRow}
                handleInputChange={this.handleInputChange}
                setFocusedInput={(barcode) => this.setState({focusedInputBarcode: barcode})}
            />
        })
    }

    updateItem(){

    }

    simonProductPage() {
        const {sizesSimone, cups, colors} = this.findProductSizesSimone();
        const {productRows} = this.state
        const {currency, variationPrices} = this.props

        const filteredByColorSizes = this.state.selectedColorSimone ? productRows.filter(product => product.colorCode === this.state.selectedColorSimone) : productRows


        const changeColor = (color) => {
            this.props.setSelectedColor(color.code)
            this.setState({selectedColorSimone: color.code})
        }

        if (!this.state.selectedColorSimone) {
            let mostStockVariation = 0
            for( let x = 0 ; x < productRows.length ; x++ ){
                mostStockVariation = 0
                productRows[x].sizes.map( ( rowMap ) => {
                    mostStockVariation += rowMap.stock;
                    return mostStockVariation;
                })
                if( mostStockVariation ){
                    this.setState({selectedColorSimone: productRows.length ? productRows[x].colorCode : null})
                    break;
                }
            }
            if( !mostStockVariation ){
                this.setState({selectedColorSimone: productRows.length ? productRows[0].colorCode : null})
            }
        }

        let totalAll = 0
        let totalPriceAll = 0

        for( let x = 0 ; x < productRows.length ; x++ ){
            let tempArray = productRows[x].sizes.length && productRows[x].sizes.map( ( rowMap ) => {
                totalAll += rowMap.inputValue;
                totalPriceAll += rowMap.inputValue * rowMap.price
                return rowMap;
            })
        }

        return <>
            <div className='simone-main-container'>
                <div className='color-container-div'>
                    <p className='color-list-header'>
                        Color
                    </p>
                    {colors.map((color, key) => <div
                        className={this.state.selectedColorSimone === color.code ? 'color-container' : 'color-container passive-color'}
                        onClick={() => changeColor(color)} key={key}>
                        <div className='color-selector-circle-container'>
                            <div className='color-selector-circle' style={{
                                background: `${color.hex}`,
                            }}>
                            </div>
                        </div>
                        <div className='color-selector-info'>
                            <p className='color-selected-info-text'>
                                {color.code + ' ' + color.name}
                            </p>
                        </div>
                    </div>)}
                </div>
                <div className='size-selector-div'>
                    <div className='size-selector-container'>
                        <div className='size-selector-sub-container'>
                            <div className='size-selector-header'>
                                <div className='size-container-icon'>
                                    <img className='size-icon' src='/CupSize.svg'/>
                                </div>
                                {sizesSimone.map((size, key) => <div
                                    style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                    className='size-container' key={key}>
                                    <p className='size-text'>
                                        {size}
                                    </p>
                                </div>)}
                            </div>
                            {cups.map((cup, key) => <div
                                className={cups.length - 1 === key ? 'cup-row margin-bottom-zero' : 'cup-row'} key={key}>
                                <div className='cup-text-container'>
                                    <p className='cup-text'>
                                        {cup}
                                    </p>
                                </div>
                                {sizesSimone.map((size, key) => {
                                        const actualVariation = filteredByColorSizes[0] && filteredByColorSizes[0].sizes.filter(sizeObject => sizeObject.cupCode === cup && sizeObject.sizeName === size)
                                        return (actualVariation && actualVariation.length) ?
                                            <div style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                                 className='size-container' key={key}>
                                                <input
                                                    max={size.stock}
                                                    type="number"
                                                    value={size.inputValue}
                                                    onChange={(e) => this.handleInputChange(e, actualVariation[0].barcode,colors.filter( color => color.code === this.state.selectedColorSimone )[0].colorId )}
                                                    className={actualVariation[0].stock ? 'size-input' : 'size-input passive-color'}
                                                    min={0}
                                                    type="number"
                                                    value={actualVariation[0].inputValue}
                                                    disabled={!actualVariation[0].stock}
                                                />
                                                <p className='size-stock-text'>
                                                    {
                                                        actualVariation[0].stock === 999 ? '' : <>Stock: {actualVariation[0].stock}</>
                                                    }
                                                </p>
                                            </div> : <div style={{width: `calc(((100% - 50px) / ${sizesSimone.length}))`}}
                                                          className='size-container'></div>
                                    }
                                )}
                            </div>)}
                        </div>
                        <div className='total-col-sub-container'>
                            <div className='total-text-container'>
                                <p className='total-text'>
                                    Total
                                </p>
                            </div>
                            {cups.map((cup, key) => {
                                let total = 0
                                let totalPrice = 0
                                const tempArray = filteredByColorSizes.length && filteredByColorSizes[0].sizes.filter( row => row.cupCode === cup ).map( ( rowMap ) => {
                                    total += rowMap.inputValue;
                                    totalPrice += rowMap.inputValue * rowMap.price
                                    return rowMap;
                                } )
                                return <div
                                    className={cups.length - 1 === key ? 'total-row-container margin-bottom-zero' : 'total-row-container'}>
                                    <div className='totat-price-container'>
                                        <p className='total-price-text'>
                                            {total}
                                            <br/>
                                            <span>
                                        € {totalPrice.toFixed(2)}
                                        </span>
                                        </p>
                                    </div>
                                    <div className='total-number-input-container'>
                                        <div className='plus-minus-container'>
                                            <div
                                                onClick={ () => this.alterRow(colors.filter( color => color.code === this.state.selectedColorSimone )[0].colorId, 'minus', cup ) }
                                                className='total-cta-container'>
                                                <img src='/remove_product.svg'/>
                                            </div>
                                            <div
                                                onClick={ () => this.alterRow(colors.filter( color => color.code === this.state.selectedColorSimone )[0].colorId, 'plus', cup ) }
                                                className='total-cta-container'>
                                                <img className='plus-cta-icon' src='/add_product.svg'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='total-for-all-colors-container'>
                <p>
                    Total for all colors: {totalAll}
                    <span>
                    € {totalPriceAll.toFixed(2)}
                </span>
                </p>
            </div>
            <div className='cta-button-container'>
                <Button callback={this.addToCart} className="add-to-cart-button vamp-button-blue order-grid-button-simone" title="Add to Order"/>
                <Button callback={this.resetGridAction} className="reset-grid-button reset-grid-button-simone" title="Reset">
                    <BinSvg/>
                </Button>
            </div>
        </>
    }

    render() {
        const {inputValues, currency, product} = this.props
        const {productRows, focusedInputBarcode} = this.state
        const {sizesSimone, cups, colors} = this.findProductSizesSimone();
        const sizes = this.findProductSizes();


        const hidePrices = localStorage.getItem('vamp_app_hide_prices')

        return product.factory === "Simone" ?
            <div>
                {this.simonProductPage()}
            </div>
            : <div className="multi-grid-wrapper">
                <table className="multigrid-table" cellPadding="0" cellSpacing="0" border="0">
                    <tbody>
                    <MultiGridHeader sizes={sizes} hidePrices={hidePrices}/>
                    {this.printMultiGridRows(hidePrices)}
                    </tbody>
                </table>
                <MultiGridTotals currency={currency} rows={productRows}/>
                <MultiGridControls editMode={!!(inputValues && inputValues.length)}
                                   resetGridAction={this.resetGridAction}
                                   addToCart={this.addToCart}/>
                <TabletControls
                    activeBarcode={focusedInputBarcode}
                    inputsSelector=".multigrid-table input"
                    remove={() => this.alterInput(focusedInputBarcode, 'minus')}
                    add={() => this.alterInput(focusedInputBarcode, 'plus')}
                />
            </div>

    }

}

export default MultiGrid
